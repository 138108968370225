@import "/src/ui/style/config";

.image-dialog {
	img {
		width: 100%;
	}

	&_loaded {
		iframe {
			visibility: visible;
		}

		.image-dialog__loading {
			display: none;
		}
	}

	&__loading {
		display: flex;
		height: 200px;
		justify-content: center;
		align-items: center;
	}
}
