@import "/src/ui/style/config";

.download-as-image {
	padding: @indent-large;

	div {
		margin-bottom: @indent-large;
	}

	a {
		color: @color-link;
		padding: @indent-small;

		&:hover {
			color: @color-link-hover;
			text-decoration: underline;
		}
	}
}
