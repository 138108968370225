@import "/src/ui/style/config";

.alert {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: @indent-large (@indent-large * 2);
	min-height: 80px;
	font-size: @font-size-docs;
	line-height: @line-height-docs;

	:root &__icon {
		font-size: @font-size-extra-big;
		color: @color-success-bg;
		margin-right: @indent-large;

		&_error {
			color: @color-danger-bg;
		}

		&_mail {
			color: @color-warning-bg;
		}
	}

	&__alert {
	}
}
