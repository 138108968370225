@import "config";

.icon {
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	display: inline-flex;
	width: 16px;
	height: 16px;
}

.svg-icon {
	font-size: @font-size-button-icon;
	vertical-align: middle;
	width: 1em;
	min-width: 1em;
	height: 1em;
	min-height: 1em;
}
