@import "/src/ui/style/config";

@dialog-height: 700px;

.dialog {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: @z-index-dialog;
	display: flex;
	align-items: center;
	justify-content: center;
	animation-name: dialog-bg-show;
	animation-fill-mode: forwards;
	animation-timing-function: linear;
	animation-duration: 0.1s;


	&__box {
		width: var(--width, 800px);
		max-width: 95vw;
		min-height: 500px;
		height: @dialog-height;
		max-height: 95vh;
		background-color: @color-dark-face-bg;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
		border-radius: @border-radius;
		animation-name: dialog-show;
		animation-fill-mode: forwards;
		animation-timing-function: ease-out;
		animation-duration: 0.1s;

		.dialog_alert & {
			background-color: @color-indicator-bg;
		}

		.dialog_small & {
			width: 400px;
		}

		.dialog_auto-height & {
			height: auto;
			min-height: 0;
			max-height: none;
		}
	}

	&__custom-content {
		padding: (@indent-large * 2);
		font-size: @font-size-docs;
		line-height: normal;

		h2 {
			margin: 0 0 @indent-large 0;
			font-weight: 300;
		}

		ul, ol {
			padding: 0 0 0 (@indent-large * 2);
		}

		a {
			border-bottom: 1px solid @color-link;
			color: @color-link;

			&:hover {
				color: @color-link-hover;
				border-color: @color-link-hover;
			}
		}
	}

	&__head {
		color: @color-dark-face-txt-muted;
		font-size: @font-size-default;
		display: flex;
		align-items: center;
		height: @size-base-height;
		border-bottom: 1px solid @color-dark-face-brd;
		box-sizing: border-box;

		span {
			flex: 1;
			display: block;
			padding: 0 @indent-large;
		}
	}

	&__close-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: @font-size-default;
		color: @color-dark-face-txt-muted;
		width: @size-base-height;
		height: @size-base-height;

		svg {
			font-size: @font-size-default;
		}

		&:hover {
			color: @color-dark-face-txt;
		}
	}

	&__buttons {
		box-sizing: border-box;
		height: @size-base-height;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid @color-dark-face-brd;

		.button {
			padding-left: @indent-large * 2;
			padding-right: @indent-large * 2;

			@media screen and (max-width: 500px) {
				padding-left: @indent-large;
				padding-right: @indent-large;
			}
		}
	}

	&__body {
		height: @dialog-height;
		max-height: 90vh;
		overflow: hidden;
		overflow-y: auto;
		color: @color-dark-face-txt;
		border-radius: 0 0 @border-radius @border-radius;

		.dialog_alert & {
			color: @color-indicator-txt;
		}

		.dialog_has-title &,
		.dialog_has-buttons & {
			height: (@dialog-height - @size-base-height);
			max-height: calc(~"90vh - @{size-base-height}");
		}

		.dialog_has-buttons.dialog_has-title & {
			height: (@dialog-height - @size-base-height * 2);
			max-height: calc(~"90vh - @{size-base-height} * 2");
		}

		:root .dialog_auto-height & {
			height: auto;
		}
	}

	&__no-body {
		padding: 20px;
		text-align: center;
		color: @color-dark-face-txt-kbd;
	}
}

@keyframes dialog-bg-show {
	from {
		background-color: rgba(0, 0, 0, 0);
	}

	to {
		background-color: rgba(0, 0, 0, 0.4);
	}
}


@keyframes dialog-show {
	from {
		transform: scale(0.8) translate(0, -40px);
	}

	to {
		transform: scale(1) translate(0, 0);
	}
}
