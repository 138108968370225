@import "src/ui/style/config";

@logo-icon-size: 22px;

.header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: @size-header-height;
	z-index: @z-index-header;
	font-size: @font-size-default;
	cursor: default;

	&_hide {
		.header__line1 {
			margin-top: -@size-header-height;
		}
	}

	&:hover {
		.header__line1 {
			margin-top: 0;
		}
	}

	&__line1 {
		background-color: @color-dark-face-bg;
		display: flex;
		align-items: stretch;
		position: relative;
		height: @size-header-height;
		transition: margin-top 0.4s ease-in-out;
	}

	&__user-name {
		padding-left: @indent-small;

		@media screen and (max-width: 420px) {
			display: none;
		}
	}

	&__logo {
		display: flex;
		align-items: center;
		color: @color-dark-face-txt;
		padding: 0 @indent-small 0 @indent-large;
		cursor: pointer;

		@media screen and (max-width: 420px) {
			display: none;
		}

		&:hover {
			background-color: @color-dark-face-bg-lighter;
		}
	}

	&__title {
		margin: 0 0 0 3px;
		font-size: @font-size-default;
		color: @color-dark-face-txt;
		font-weight: normal;
	}

	&__lang {
		white-space: nowrap;
		display: flex;
		align-items: center;
		margin-right: @indent-large;
		color: @color-dark-face-txt-muted;
	}

	&__button {
		display: flex;
		align-items: center;
		padding: 0 @indent-large;
		color: @color-dark-face-txt;
		cursor: pointer;
		user-select: none;
		transition: background-color 0.1s linear;
		white-space: nowrap;

		&[hidden] {
			display: none;
		}

		&:visited {
			color: @color-dark-face-txt;
		}

		&_pressed, &_pressed:hover {
			background-color: @color-main-bg;
		}

		&_switch {
			@media screen and (max-width: 800px) {
				display: none;
			}

			&[data-value="on"] svg[data-value="off"] {
				display: none;
			}

			&[data-value="off"] svg[data-value="on"] {
				display: none;
			}
		}

		&:hover {
			background-color: @color-dark-face-bg-lighter;
		}
	}

	&__button-counter {
		margin-left: @indent-small;
		border-radius: 6px;
		padding: 0 @indent-small;
		background-color: @color-warning-bg-darker;
		font-size: @font-size-kbd;

		&:empty {
			display: none;
		}
	}

	&__button-label {
		margin-right: @indent-small;
		border-radius: 6px;
		padding: 0 @indent-small;
		background-color: @color-success-bg;
		color: @color-dark-color2-txt;
		font-size: 10px;
	}

	&__button-icon {
		margin-right: @indent-small;
		width: 22px;
		height: 22px;
	}

	&__free-cell {
		flex: 1;
	}
}
