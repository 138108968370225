html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font: 12px/16px Tahoma, Helvetica, Arial, Verdana;
  background-color: #444;
}
main {
  height: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  color: inherit;
}
a:active {
  color: inherit;
}
.button {
  display: inline-flex;
  height: 36px;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  color: #efefef;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  align-items: center;
  transition: background-color 0.2s linear;
}
.button[hidden] {
  display: none;
}
.button svg {
  font-size: 18px;
  vertical-align: middle;
}
.button span {
  color: #efefef;
  font-size: 12px;
  vertical-align: middle;
  margin-left: 5px;
  white-space: nowrap;
}
.button kbd {
  font-size: 11px;
  color: #838383;
  margin-left: 5px;
  margin-top: 10px;
}
.button:hover {
  background-color: #575b65;
}
.button:hover span {
  color: #efefef;
}
.button:hover kbd {
  color: #efefef;
}
.button:active {
  background-color: #333;
}
.button:active span {
  color: #efefef;
}
.button:active kbd {
  color: #efefef;
}
:root .button:disabled,
:root .button_disabled {
  pointer-events: none;
}
:root .button:disabled i,
:root .button_disabled i,
:root .button:disabled svg,
:root .button_disabled svg {
  color: #717171;
}
:root .button:disabled span,
:root .button_disabled span {
  color: #717171;
}
.button_border {
  border: 1px solid #555;
}
.button_border-light {
  border: 1px solid #838383;
}
.button_success {
  background-color: #49884c;
  color: #ecf8ec;
}
.button .button__switch {
  margin-right: 5px;
  width: 22px;
  height: 22px;
}
.button .button__switch use[href="/images/icons.svg#icon-svg-turn-off"] {
  display: inline;
  color: #aaa;
}
.button .button__switch use[href="/images/icons.svg#icon-svg-turn-on"] {
  display: none;
  color: #efefef;
}
.button_checked {
  background-color: #333;
}
.button_checked:hover {
  background-color: #333;
}
.button_checked svg.button__switch use[href="/images/icons.svg#icon-svg-turn-off"] {
  display: none;
}
.button_checked svg.button__switch use[href="/images/icons.svg#icon-svg-turn-on"] {
  display: inline;
}
.button .button__marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #49884c;
}
.button .button__marker_alert {
  background-color: #db4437;
  box-shadow: 0 0 6px #ed7368;
}
.button[hidden] {
  display: none;
}
.button svg {
  font-size: 18px;
  vertical-align: middle;
}
.button span {
  color: #efefef;
  font-size: 12px;
  vertical-align: middle;
  margin-left: 5px;
  white-space: nowrap;
}
.button kbd {
  font-size: 11px;
  color: #838383;
  margin-left: 5px;
  margin-top: 10px;
}
.button:hover {
  background-color: #575b65;
}
.button:hover span {
  color: #efefef;
}
.button:hover kbd {
  color: #efefef;
}
.button:active {
  background-color: #333;
}
.button:active span {
  color: #efefef;
}
.button:active kbd {
  color: #efefef;
}
:root .button:disabled,
:root .button_disabled {
  pointer-events: none;
}
:root .button:disabled i,
:root .button_disabled i,
:root .button:disabled svg,
:root .button_disabled svg {
  color: #717171;
}
:root .button:disabled span,
:root .button_disabled span {
  color: #717171;
}
.button_border {
  border: 1px solid #555;
}
.button_border-light {
  border: 1px solid #838383;
}
.button_success {
  background-color: #49884c;
  color: #ecf8ec;
}
.button .button__switch {
  margin-right: 5px;
  width: 22px;
  height: 22px;
}
.button .button__switch use[href="/images/icons.svg#icon-svg-turn-off"] {
  display: inline;
  color: #aaa;
}
.button .button__switch use[href="/images/icons.svg#icon-svg-turn-on"] {
  display: none;
  color: #efefef;
}
.button_checked {
  background-color: #333;
}
.button_checked:hover {
  background-color: #333;
}
.button_checked svg.button__switch use[href="/images/icons.svg#icon-svg-turn-off"] {
  display: none;
}
.button_checked svg.button__switch use[href="/images/icons.svg#icon-svg-turn-on"] {
  display: inline;
}
.button .button__marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #49884c;
}
.button .button__marker_alert {
  background-color: #db4437;
  box-shadow: 0 0 6px #ed7368;
}
.fatal-error {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
}
.fatal-error__icon.svg-icon {
  font-size: 22px;
}
.fatal-error__body {
  flex: 1;
  padding: 40px;
  background-color: #db4437;
  color: #fff;
  text-align: center;
  font-size: 18px;
}
.fatal-error__body a {
  color: #fff;
  text-decoration: underline;
}
.fatal-error__body a:hover {
  color: #fff;
}
.fatal-error__body a:visited {
  color: #fff;
}
.fatal-error__buttons button,
.fatal-error__buttons .button {
  display: inline-block;
  min-width: 100px;
  text-align: center;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 7px 30px;
  background-color: #ed7368;
  border: 1px solid #ed7368;
  color: #fff;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
.fatal-error__buttons button:hover,
.fatal-error__buttons .button:hover {
  border-color: #fff;
}
.fatal-error__buttons button:active,
.fatal-error__buttons .button:active {
  background-color: #841e15;
  border-color: #841e15;
  color: #fff;
}
.spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2000;
  transition: background-color 0.6s ease-out;
  cursor: wait;
  visibility: hidden;
}
.spinner_show {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.4);
}
.dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: dialog-bg-show;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-duration: 0.1s;
}
.dialog__box {
  width: var(--width, 800px);
  max-width: 95vw;
  min-height: 500px;
  height: 700px;
  max-height: 95vh;
  background-color: #444;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  animation-name: dialog-show;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.1s;
}
.dialog_alert .dialog__box {
  background-color: #254aa7;
}
.dialog_small .dialog__box {
  width: 400px;
}
.dialog_auto-height .dialog__box {
  height: auto;
  min-height: 0;
  max-height: none;
}
.dialog__custom-content {
  padding: 20px;
  font-size: 14px;
  line-height: normal;
}
.dialog__custom-content h2 {
  margin: 0 0 10px 0;
  font-weight: 300;
}
.dialog__custom-content ul,
.dialog__custom-content ol {
  padding: 0 0 0 20px;
}
.dialog__custom-content a {
  border-bottom: 1px solid #c4d7e3;
  color: #c4d7e3;
}
.dialog__custom-content a:hover {
  color: #f2faff;
  border-color: #f2faff;
}
.dialog__head {
  color: #aaa;
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid #555;
  box-sizing: border-box;
}
.dialog__head span {
  flex: 1;
  display: block;
  padding: 0 10px;
}
.dialog__close-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #aaa;
  width: 36px;
  height: 36px;
}
.dialog__close-button svg {
  font-size: 12px;
}
.dialog__close-button:hover {
  color: #efefef;
}
.dialog__buttons {
  box-sizing: border-box;
  height: 36px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #555;
}
.dialog__buttons .button {
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (max-width: 500px) {
  .dialog__buttons .button {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.dialog__body {
  height: 700px;
  max-height: 90vh;
  overflow: hidden;
  overflow-y: auto;
  color: #efefef;
  border-radius: 0 0 6px 6px;
}
.dialog_alert .dialog__body {
  color: #fff;
}
.dialog_has-title .dialog__body,
.dialog_has-buttons .dialog__body {
  height: 664px;
  max-height: calc(90vh - 36px);
}
.dialog_has-buttons.dialog_has-title .dialog__body {
  height: 628px;
  max-height: calc(90vh - 36px * 2);
}
:root .dialog_auto-height .dialog__body {
  height: auto;
}
.dialog__no-body {
  padding: 20px;
  text-align: center;
  color: #838383;
}
@keyframes dialog-bg-show {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
@keyframes dialog-show {
  from {
    transform: scale(0.8) translate(0, -40px);
  }
  to {
    transform: scale(1) translate(0, 0);
  }
}
.form {
  margin: 0;
  overflow: hidden;
}
.form__section {
  border-bottom: 1px solid #555;
  flex: 1;
  padding: 5px 10px;
  color: #aaa;
  margin: 0 -10px;
}
.form__error {
  display: none;
  padding: 10px;
  background-color: #db4437;
  color: #fff;
  text-align: center;
}
.form__error_show {
  display: block;
}
.form__note {
  display: none;
  padding: 10px;
  background-color: #254aa7;
  color: #fff;
  text-align: center;
}
.form__note_show {
  display: block;
}
.form__message {
  padding: 10px;
  background-color: #575b65;
  text-align: center;
}
.form__message:empty {
  display: none;
}
.form__message a {
  color: #c4d7e3;
  text-decoration: underline;
}
.form__message a:hover {
  color: #f2faff;
}
.form__group {
  display: flex;
  align-items: center;
  padding: 10px;
}
.form__group[disabled] {
  pointer-events: none;
  opacity: 0.6;
}
.form:not(.form_inline) .form__error + .form__group {
  margin-top: 10px;
}
.form:not(.form_inline) .form__group:last-of-type {
  margin-bottom: 10px;
}
.form_no-margin .form__group {
  margin: 0 !important;
}
.form__group + .form__group {
  padding-top: 0;
}
.form__caption {
  display: block;
  width: 30%;
  max-width: 30%;
  text-align: right;
  color: #efefef;
  padding-right: 5px;
}
.form_width-50 .form__caption {
  width: 50%;
  max-width: 50%;
}
.form_no-captions .form__caption {
  display: none;
}
.form__caption_for-options {
  align-self: start;
  min-height: 20px;
  line-height: 20px;
}
.form_inline .form__caption {
  width: auto;
  max-width: none;
}
.form__caption:not(:empty)::after {
  content: ":";
}
.form__hint {
  margin-left: 30%;
  padding-left: 10px;
  color: #aaa;
  margin-bottom: 10px;
}
.form_width-50 .form__hint {
  margin-left: 50%;
}
.form__options {
  display: block;
  width: 70%;
}
.form_width-50 .form__options {
  width: 50%;
}
.form__option {
  display: block;
  width: fit-content;
  margin-bottom: 5px;
  color: #efefef;
  position: relative;
  box-sizing: border-box;
  min-height: 20px;
  line-height: 20px;
  padding-left: 25px;
}
.form__option input {
  vertical-align: middle;
  margin-left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.form__option span::before {
  content: "";
  position: absolute;
  display: block;
  opacity: 0;
  height: 10px;
  width: 10px;
  background-color: #fff;
  left: 5px;
  top: 5px;
  border-radius: 50%;
  z-index: 2;
}
.form__option span::after {
  content: "";
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  background-color: #333;
  border: 1px solid #555;
  color: #333;
  border-radius: 50%;
  left: 0;
  top: 0;
  z-index: 1;
}
.form__group_error .form__option span::after {
  border: 1px solid #db4437;
}
.form__group_indicate .form__option span::after {
  border: 1px solid #4576f4;
}
.form__option input:checked + span::before {
  opacity: 1;
}
.form__option input:checked + span::after {
  background-color: #254aa7;
  border: 1px solid #4576f4;
}
.form__group_error .form__option input:checked + span::after {
  border: 1px solid #db4437;
}
.form__group_indicate .form__option input:checked + span::after {
  border: 1px solid #4576f4;
}
.form__label {
  display: block;
  width: 70%;
  color: #efefef;
  position: relative;
  box-sizing: border-box;
  min-height: 20px;
  line-height: 20px;
  padding-left: 25px;
}
.form_width-50 .form__label {
  width: 50%;
}
.form_inline .form__label {
  width: auto;
  max-width: none;
}
.form__label input {
  vertical-align: middle;
  margin-left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.form__label span::before {
  content: "";
  position: absolute;
  display: block;
  opacity: 0;
  height: 10px;
  width: 10px;
  background-color: #fff;
  left: 5px;
  top: 5px;
  border-radius: 50%;
  z-index: 2;
}
.form__label span::after {
  content: "";
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  background-color: #333;
  border: 1px solid #555;
  color: #333;
  border-radius: 0;
  left: 0;
  top: 0;
  z-index: 1;
}
.form__group_error .form__label span::after {
  border: 1px solid #db4437;
}
.form__group_indicate .form__label span::after {
  border: 1px solid #4576f4;
}
.form__label input:checked + span::before {
  opacity: 1;
}
.form__label input:checked + span::after {
  background-color: #254aa7;
  border: 1px solid #4576f4;
}
.form__group_error .form__label input:checked + span::after {
  border: 1px solid #db4437;
}
.form__group_indicate .form__label input:checked + span::after {
  border: 1px solid #4576f4;
}
.form__range-scale {
  width: 180px;
  height: 5px;
  display: flex;
  font-size: 11px;
  padding-left: 10px;
  margin-top: 18px;
}
.form__range-scale_double {
  width: 380px;
}
.form__range-scale div {
  flex: 1;
  box-sizing: border-box;
  border-right: 1px solid #838383;
  position: relative;
}
.form__range-scale div:first-child {
  border-left: 1px solid #838383;
}
.form__range-scale div[data-caption]:not(:last-child)::before {
  content: attr(data-caption);
  color: #838383;
  position: absolute;
  left: -10px;
  top: -18px;
  width: 20px;
  height: 18px;
  text-align: center;
}
.form__range-scale div[data-caption]:last-child::after {
  content: attr(data-caption);
  color: #838383;
  position: absolute;
  right: -10px;
  top: -18px;
  width: 20px;
  height: 18px;
  text-align: center;
}
.form__range {
  flex: 1;
}
.form__range input {
  width: 200px;
}
.form__range span {
  margin-left: 5px;
  color: #fff;
}
.form__range_double input {
  width: 400px;
}
.form__range-input {
  display: flex;
  align-items: center;
}
.form__input {
  display: block;
}
.form__input_big {
  width: 70%;
}
.form_width-50 .form__input_big {
  width: 50%;
}
.form__input input {
  background-color: #333;
  border: 1px solid #555;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
}
.form__input input[readonly] {
  background-color: transparent;
}
.form__group_error .form__input input {
  border: 1px solid #db4437;
}
.form__group_indicate .form__input input {
  border: 1px solid #4576f4;
}
.form__textarea {
  display: block;
  flex: 1;
}
.form__textarea textarea {
  width: 100%;
  height: 110px;
  resize: none;
  background-color: #333;
  border: 1px solid #555;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  box-sizing: border-box;
}
.form__textarea textarea[readonly] {
  background-color: transparent;
}
.form__group_error .form__textarea textarea {
  border: 1px solid #db4437;
}
.form__group_indicate .form__textarea textarea {
  border: 1px solid #4576f4;
}
.form__textarea_big textarea {
  height: 300px;
}
.form__select select {
  background-color: #333;
  border: 1px solid #555;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
}
.form__select select[readonly] {
  background-color: transparent;
}
.form__group_error .form__select select {
  border: 1px solid #db4437;
}
.form__group_indicate .form__select select {
  border: 1px solid #4576f4;
}
.form__image {
  position: relative;
  background-color: #575b65;
  line-height: 0;
}
.form__image span {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 16px;
}
.form__image img {
  max-width: 100%;
  max-height: 300px;
  min-width: 200px;
  min-height: 100px;
}
.form__image_loading img {
  visibility: hidden;
}
.form__image_loading span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-menu {
  position: fixed;
  background-color: #e7e7e7;
  border: 1px solid #838383;
  border-radius: 6px;
  color: #444;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  left: var(--x, 0);
  top: var(--y, 0);
  z-index: 1200;
  overflow: hidden;
}
.popup-menu__button {
  display: flex;
  height: 36px;
  background-color: transparent;
  cursor: pointer;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  align-items: center;
}
.popup-menu__button svg {
  font-size: 18px;
  vertical-align: middle;
}
.popup-menu__button span {
  margin-left: 5px;
}
.popup-menu__button:hover {
  background-color: #575b65;
}
.popup-menu__button:hover span {
  color: #efefef;
}
.popup-menu__button:hover svg {
  color: #efefef;
}
.popup-menu__button:active {
  background-color: #333;
}
.popup-menu__button:active span {
  color: #efefef;
}
.popup-menu__button:active svg {
  color: #efefef;
}
.scenes-matrix {
  position: relative;
  background-color: #333;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: grab;
}
.scenes-matrix__row {
  display: flex;
  width: calc(var(--width) * var(--columns));
}
.scenes-matrix__cell {
  width: calc(var(--width) - (var(--indent) * 2));
  height: calc(var(--height) - (var(--indent) * 2));
  overflow: hidden;
}
.scenes-matrix__cell video {
  width: var(--width);
}
.scenes-matrix > canvas {
  position: relative;
  z-index: 1;
}
.video-scene {
  background-image: var(--bg-image);
  background-size: var(--width) var(--height);
}
.video-scene_error {
  background-image: var(--bg-image);
  background-size: 20% 20%, var(--width) var(--height);
}
.slots-matrix {
  position: absolute;
  z-index: 0;
  background-color: #333;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.slots-matrix__row {
  display: flex;
  width: calc(var(--width) * var(--columns));
}
.slots-matrix__cell {
  width: var(--width);
  height: var(--height);
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px;
  white-space: pre;
  background-size: calc(var(--width) * 2) calc(var(--height) * 2);
  background-position: calc((var(--col) * var(--width)) * -1) calc((var(--row) * var(--height)) * -1);
}
.slots-matrix__cell:before {
  content: attr(data-slot-id);
  display: block;
}
.slots-matrix__cell:after {
  content: attr(data-slot-size);
  display: block;
}
.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 28px;
  z-index: 300;
  font-size: 12px;
  cursor: default;
}
.header_hide .header__line1 {
  margin-top: -28px;
}
.header:hover .header__line1 {
  margin-top: 0;
}
.header__line1 {
  background-color: #444;
  display: flex;
  align-items: stretch;
  position: relative;
  height: 28px;
  transition: margin-top 0.4s ease-in-out;
}
.header__user-name {
  padding-left: 5px;
}
@media screen and (max-width: 420px) {
  .header__user-name {
    display: none;
  }
}
.header__logo {
  display: flex;
  align-items: center;
  color: #efefef;
  padding: 0 5px 0 10px;
  cursor: pointer;
}
@media screen and (max-width: 420px) {
  .header__logo {
    display: none;
  }
}
.header__logo:hover {
  background-color: #575b65;
}
.header__title {
  margin: 0 0 0 3px;
  font-size: 12px;
  color: #efefef;
  font-weight: normal;
}
.header__lang {
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #aaa;
}
.header__button {
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #efefef;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.1s linear;
  white-space: nowrap;
}
.header__button[hidden] {
  display: none;
}
.header__button:visited {
  color: #efefef;
}
.header__button_pressed,
.header__button_pressed:hover {
  background-color: #333;
}
@media screen and (max-width: 800px) {
  .header__button_switch {
    display: none;
  }
}
.header__button_switch[data-value="on"] svg[data-value="off"] {
  display: none;
}
.header__button_switch[data-value="off"] svg[data-value="on"] {
  display: none;
}
.header__button:hover {
  background-color: #575b65;
}
.header__button-counter {
  margin-left: 5px;
  border-radius: 6px;
  padding: 0 5px;
  background-color: #ba8a2c;
  font-size: 11px;
}
.header__button-counter:empty {
  display: none;
}
.header__button-label {
  margin-right: 5px;
  border-radius: 6px;
  padding: 0 5px;
  background-color: #49884c;
  color: #fff;
  font-size: 10px;
}
.header__button-icon {
  margin-right: 5px;
  width: 22px;
  height: 22px;
}
.header__free-cell {
  flex: 1;
}
.change-area {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s linear;
  pointer-events: none;
}
.change-area_show {
  visibility: visible;
  opacity: 1;
}
.change-area__head {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  bottom: calc(100vh - var(--y1, 0) + 10px);
  left: calc(var(--x1, 0) - 80px);
  width: calc(var(--x2, 0) - var(--x1, 0) + 160px);
  z-index: 2;
}
.change-area_animated .change-area__head {
  transition: left 200ms, bottom 200ms;
}
.change-area__top-line {
  display: flex;
  align-items: center;
}
.change-area__title {
  color: #fff;
  padding: 5px;
  border-radius: 6px;
  max-width: 280px;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  z-index: 2;
  text-wrap: pretty;
}
.change-area__title a {
  color: #fce38f;
  pointer-events: visible;
  cursor: pointer;
  text-decoration: underline;
}
.change-area__title a:hover {
  color: #f2faff;
}
.change-area__button {
  padding: 5px;
  pointer-events: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 6px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  transition: background-color 0.2s;
}
.change-area__button[href="#"],
.change-area__button[hidden] {
  display: none;
}
.change-area__button span {
  color: #000;
  font-size: 11px;
  margin-left: 3px;
}
.change-area__button .svg-icon {
  font-size: 12px;
  color: #000;
}
.change-area__button:hover {
  background-color: #fff;
}
.change-area__interactive {
  display: flex;
  justify-content: center;
  margin-top: 3px;
  z-index: 2;
}
.change-area__interactive[hidden] {
  display: none;
}
.change-area__interactive-button {
  display: inline-flex;
  padding: 5px 10px;
  background-color: #56bc4e;
  border: 1px solid #047f41;
  color: #fff;
  pointer-events: visible;
  cursor: pointer;
  border-radius: 6px;
  z-index: 2;
}
.change-area__interactive-button:hover {
  background-color: #95d079;
}
.change-area__shadow {
  position: absolute;
  top: var(--y1, 0);
  left: var(--x1, 0);
  width: calc(var(--x2, 0) - var(--x1, 0));
  height: calc(var(--y2, 0) - var(--y1, 0));
  box-shadow: 0 0 0 500vmax rgba(0, 0, 0, 0.3);
  z-index: 1;
  border-radius: 6px;
}
.change-area_animated .change-area__shadow {
  transition: left 200ms, top 200ms, width 200ms, height 200ms;
}
.changes-bar {
  position: fixed;
  left: -300px;
  top: 28px;
  height: calc(100% - 28px);
  width: 300px;
  max-width: 100vw;
  z-index: 300;
  background-color: #444;
  border-top: 1px solid #555;
  border-right: 1px solid #555;
  box-sizing: border-box;
  transition: left 0.2s ease-out;
}
@media screen and (max-width: 600px) {
  .changes-bar {
    width: 100vw;
    left: -100vw;
  }
}
.changes-bar_show {
  left: 0;
}
.changes-bar__loading {
  padding: 10px;
  color: #aaa;
  text-align: center;
}
.changes-bar__list_eof .changes-bar__loading {
  display: none;
}
.changes-bar__form {
  display: flex;
  border-bottom: 1px solid #555;
  height: 36px;
  align-items: center;
}
.changes-bar__form[hidden] {
  display: none;
}
.changes-bar__form[hidden] ~ .changes-bar__list {
  height: calc(100% - (28px + 1px));
}
.changes-bar__list {
  height: calc(100% - (36px + 1px) - (28px + 1px));
  overflow-y: auto;
}
.changes-bar__search {
  display: flex;
  height: 36px;
  align-items: center;
  border-left: 1px solid #555;
}
.changes-bar__search input {
  flex: 1;
  height: 36px;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0 10px 0 0;
  color: #efefef;
  font-size: 12px;
}
.changes-bar__search input::-webkit-search-decoration,
.changes-bar__search input::-webkit-search-cancel-button,
.changes-bar__search input::-webkit-search-results-button,
.changes-bar__search input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.changes-bar__search label > svg {
  color: #aaa;
  font-size: 12px;
  margin: 0 10px 0 15px;
  vertical-align: text-bottom;
}
.changes-bar__share {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  gap: 10px;
  color: #aaa;
  padding: 0 10px;
}
.changes-bar__share a {
  color: #c4d7e3;
}
.changes-bar__share a:hover {
  color: #f2faff;
}
.changes-bar__share svg {
  width: 20px;
  height: 20px;
}
.changes-bar__nav {
  display: flex;
  align-items: stretch;
  height: 28px;
  border-bottom: 1px solid #555;
  color: #efefef;
}
.changes-bar__nav-button {
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #efefef;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.1s linear;
  white-space: nowrap;
}
.changes-bar__nav-button:hover {
  background-color: #575b65;
}
.changes-bar__nav-button_pressed,
.changes-bar__nav-button_pressed:hover {
  background-color: #333;
}
.changes-bar__nav-indicator {
  color: #f9d24f;
  font-size: 12px;
  margin-right: 5px;
}
.changes-bar__nav-indicator[hidden] {
  display: none;
}
.changes-bar__error {
  background-color: #db4437;
  color: #fff;
  padding: 10px;
}
.changes-bar__error:empty {
  display: none;
}
.changes-bar__error:not(:empty) + .changes-bar__list {
  display: none;
}
.change-record {
  padding: 10px;
  color: #efefef;
  transition: background-color 0.2s linear;
  cursor: pointer;
}
.change-record:hover {
  background-color: #575b65;
}
:root .change-record_selected {
  border-left: 3px solid #4576f4;
  background-color: #333;
}
.change-record_new .change-record__new-indicator {
  display: inline;
}
.change-record_interactive .change-record__int-label {
  display: inline-block;
}
.change-record__group {
  display: flex;
  align-items: center;
  gap: 10px;
}
.change-record__content {
  flex: 1;
}
.change-record__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 26px;
  aspect-ratio: 1;
  background-color: #2AABEE;
}
.change-record__icon .svg-icon {
  font-size: 14px;
}
.change-record__icon[data-icon="music"] {
  background-color: #dc70a7;
}
.change-record__icon[data-icon="play"] {
  background-color: #d0a409;
}
.change-record__icon[data-icon="cup"] {
  background-color: #ddd;
  color: #333;
}
.change-record__int-label {
  display: none;
  background-color: #56bc4e;
  color: #fff;
  font-size: 11px;
  margin-top: 3px;
  padding: 2px 4px;
  border-radius: 3px;
}
.change-record__new-indicator {
  display: none;
  color: #f9d24f;
  font-size: 12px;
  margin-right: 5px;
}
.change-record__head {
  display: flex;
  color: #aaa;
  font-size: 11px;
}
.change-record__body {
  display: flex;
  align-items: flex-start;
}
.change-record__title {
  flex: 1;
  padding: 5px 0;
}
.change-record__button {
  display: none;
  padding: 5px;
  transition: border-color 0.2s;
  border: 1px solid transparent;
  border-radius: 3px;
}
.change-record__button[href^="http"],
.change-record__button[href^="/"] {
  display: inline;
}
.change-record__button svg {
  color: #c4d7e3;
}
.change-record__button:hover {
  border-color: #6e7481;
}
.change-record__button:hover svg {
  color: #f2faff;
}
.change-record__date {
  flex: 1;
}
.change-record__author {
  white-space: nowrap;
}
.about {
  padding: 20px;
  font-size: 14px;
  line-height: 18px;
  display: flex;
}
.about__body {
  flex: 1;
}
.about__buttons {
  margin-top: 20px;
}
.about__video {
  display: flex;
  align-items: center;
  position: relative;
}
.about__video video {
  position: relative;
  z-index: 2;
}
.about__video-loading {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
}
@media screen and (max-width: 1000px) {
  .about {
    flex-wrap: wrap;
    justify-content: center;
  }
  .about__video {
    margin-bottom: 10px;
  }
  .about video {
    width: 333px;
    height: 285px;
  }
}
@media screen and (max-width: 340px) {
  .about video {
    width: 222px;
    height: 192px;
  }
}
.about a {
  border-bottom: 1px solid #c4d7e3;
  color: #c4d7e3;
}
.about a:hover {
  color: #f2faff;
  border-color: #f2faff;
}
.interactive-dialog iframe {
  visibility: hidden;
  border: 0;
  width: 1px;
  height: 1px;
}
.interactive-dialog_loaded iframe {
  visibility: visible;
}
.interactive-dialog_loaded .interactive-dialog__loading {
  display: none;
}
.interactive-dialog__loading {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}
.image-dialog img {
  width: 100%;
}
.image-dialog_loaded iframe {
  visibility: visible;
}
.image-dialog_loaded .image-dialog__loading {
  display: none;
}
.image-dialog__loading {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}
.low-battery-dialog {
  padding: 20px;
}
.low-battery-dialog .battery-anim {
  margin: 0 auto 20px auto;
}
.low-battery-dialog__message {
  font-size: 14px;
  line-height: 18px;
}
.battery-anim {
  width: 240px;
  height: 90px;
  border: 10px solid #aaa;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
}
.battery-anim > div {
  width: 15%;
  height: 100%;
  background-color: #db4437;
}
.battery-anim > div:first-child {
  border-radius: 10px 0 0 10px;
  margin-right: 10px;
}
.battery-anim > div:last-child {
  animation: battery-blink 1s infinite;
}
@keyframes battery-blink {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
}
.download-as-image {
  padding: 10px;
}
.download-as-image div {
  margin-bottom: 10px;
}
.download-as-image a {
  color: #c4d7e3;
  padding: 5px;
}
.download-as-image a:hover {
  color: #f2faff;
  text-decoration: underline;
}
.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-height: 80px;
  font-size: 14px;
  line-height: 18px;
}
:root .alert__icon {
  font-size: 30px;
  color: #49884c;
  margin-right: 10px;
}
:root .alert__icon_error {
  color: #db4437;
}
:root .alert__icon_mail {
  color: #f9d24f;
}
* {
  scrollbar-width: 8px;
  scrollbar-color: #aaa;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 4px;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 4px;
}
.icon {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-flex;
  width: 16px;
  height: 16px;
}
.svg-icon {
  font-size: 14px;
  vertical-align: middle;
  width: 1em;
  min-width: 1em;
  height: 1em;
  min-height: 1em;
}
