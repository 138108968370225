@import "/src/ui/style/config";

.interactive-dialog {
	iframe {
		visibility: hidden;
		border: 0;
		width: 1px;
		height: 1px;
	}

	&_loaded {
		iframe {
			visibility: visible;
		}

		.interactive-dialog__loading {
			display: none;
		}
	}

	&__loading {
		display: flex;
		height: 200px;
		justify-content: center;
		align-items: center;
	}
}
