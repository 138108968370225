@import "/src/ui/style/config";

.low-battery-dialog {
	padding: (@indent-large * 2);

	.battery-anim {
		margin: 0 auto (@indent-large * 2) auto;
	}

	&__message {
		font-size: @font-size-docs;
		line-height: @line-height-docs;
	}
}

.battery-anim {
	width: 240px;
	height: 90px;
	border: 10px solid @color-dark-face-txt-muted;
	border-radius: 30px;
	box-sizing: border-box;
	padding: 10px;
	display: flex;

	& > div {
		width: 15%;
		height: 100%;
		background-color: @color-danger-bg;

		&:first-child {
			border-radius: 10px 0 0 10px;
			margin-right: 10px;
		}

		&:last-child {
			animation: battery-blink 1s infinite;
		}
	}
}

@keyframes battery-blink {
	0% {
		opacity: 1;
	}

	30% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	80% {
		opacity: 0;
	}
}
