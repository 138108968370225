@import "/src/ui/style/config";

.about {
	padding: (@indent-large * 2);
	font-size: @font-size-docs;
	line-height: @line-height-docs;
	display: flex;

	&__body {
		flex: 1;
	}

	&__buttons {
		margin-top: (@indent-large * 2);
	}

	&__video {
		display: flex;
		align-items: center;
		position: relative;

		video {
			position: relative;
			z-index: 2;
		}
	}

	&__video-loading {
		z-index: 1;
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: @color-dark-face-txt-muted;
	}

	@media screen and (max-width: 1000px) {
		flex-wrap: wrap;
		justify-content: center;

		&__video {
			margin-bottom: @indent-large;
		}

		video {
			width: 333px;
			height: 285px;
		}
	}

	@media screen and (max-width: 340px) {
		video {
			width: 222px;
			height: 192px;
		}
	}

	a {
		border-bottom: 1px solid @color-link;
		color: @color-link;

		&:hover {
			color: @color-link-hover;
			border-color: @color-link-hover;
		}
	}
}
