@import "config";
* {
	scrollbar-width: @size-scroll-bar;
	scrollbar-color: @color-face-bg;
}

::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: (@size-scroll-bar / 2);
}

::-webkit-scrollbar {
	width: @size-scroll-bar;
	height: @size-scroll-bar;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: @color-face-bg;
	border-radius: (@size-scroll-bar / 2);
}
