@import "/src/ui/style/config";

@changes-bar-width: 300px;

.changes-bar {
	position: fixed;
	left: -@changes-bar-width;
	top: @size-header-height;
	height: calc(100% - @size-header-height);
	width: @changes-bar-width;
	max-width: 100vw;
	z-index: @z-index-header;
	background-color: @color-dark-face-bg;
	border-top: 1px solid @color-dark-face-brd;
	border-right: 1px solid @color-dark-face-brd;
	box-sizing: border-box;
	transition: left 0.2s ease-out;

	@media screen and (max-width: 600px) {
		width: 100vw;
		left: -100vw;
	}

	&_show {
		left: 0;
	}

	&__loading {
		padding: @indent-large;
		color: @color-dark-face-txt-muted;
		text-align: center;

		.changes-bar__list_eof & {
			display: none;
		}
	}

	&__form {
		display: flex;
		border-bottom: 1px solid @color-dark-face-brd;
		height: @size-base-height;
		align-items: center;

		&[hidden] {
			display: none;

			& ~ .changes-bar__list {
				height: calc(100% - (@size-header-height + 1px));
			}
		}
	}

	&__list {
		height: calc(100% - (@size-base-height + 1px) - (@size-header-height + 1px));
		overflow-y: auto;
	}

	&__search {
		display: flex;
		height: @size-base-height;
		align-items: center;
		border-left: 1px solid @color-dark-face-brd;

		input {
			flex: 1;
			height: @size-base-height;
			border: none;
			background-color: transparent;
			outline: none;
			padding: 0 @indent-large 0 0;
			color: @color-dark-face-txt;
			font-size: @font-size-default;

			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration {
				-webkit-appearance:none;
			}
		}

		label > svg {
			color: @color-dark-face-txt-muted;
			font-size: @font-size-default;
			margin: 0 @indent-large 0 (@indent-large + @indent-small);
			vertical-align: text-bottom;
		}
	}

	&__share {
		display: flex;
		align-items: center;
		justify-content: center;
		height: @size-base-height;
		gap: @indent-large;
		color: @color-dark-face-txt-muted;
		padding: 0 @indent-large;

		a {
			color: @color-link;

			&:hover {
				color: @color-link-hover;
			}
		}

		svg {
			width: 20px;
			height: 20px;
		}
	}

	&__nav {
		display: flex;
		align-items: stretch;
		height: @size-header-height;
		border-bottom: 1px solid @color-dark-face-brd;
		color: @color-dark-face-txt;
	}

	&__nav-button {
		display: flex;
		align-items: center;
		padding: 0 @indent-large;
		color: @color-dark-face-txt;
		cursor: pointer;
		user-select: none;
		transition: background-color 0.1s linear;
		white-space: nowrap;

		&:hover {
			background-color: @color-dark-face-bg-lighter;
		}

		&_pressed, &_pressed:hover {
			background-color: @color-main-bg;
		}
	}

	&__nav-indicator {
		color: @color-warning-bg;
		font-size: @font-size-default;
		margin-right: @indent-small;

		&[hidden] {
			display: none;
		}
	}

	&__error {
		background-color: @color-danger-bg;
		color: @color-danger-txt;
		padding: @indent-large;

		&:empty {
			display: none;
		}

		&:not(:empty) + .changes-bar__list {
			display: none;
		}
	}
}

.change-record {
	padding: @indent-large;
	color: @color-dark-face-txt;
	transition: background-color 0.2s linear;
	cursor: pointer;

	&:hover {
		background-color: @color-face2-bg;
	}

	:root &_selected {
		border-left: 3px solid @color-indicator-brd;
		background-color: @color-main-bg;
	}

	&_new {
		.change-record__new-indicator {
			display: inline;
		}
	}

	&_interactive {
		.change-record__int-label {
			display: inline-block;
		}
	}

	&__group {
		display: flex;
		align-items: center;
		gap: @indent-large;
	}

	&__content {
		flex: 1;
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: 26px;
		aspect-ratio: 1;
		background-color: #2AABEE;

		.svg-icon {
			font-size: 14px;
		}

		&[data-icon="music"] {
			background-color: #dc70a7;
		}

		&[data-icon="play"] {
			background-color: #d0a409;
		}

		&[data-icon="cup"] {
			background-color: #ddd;
			color: #333;
		}
	}

	&__int-label {
		display: none;
		background-color: @color-dark-color2-bg;
		color: @color-dark-color2-txt;
		font-size: @font-size-kbd;
		margin-top: 3px;
		padding: 2px 4px;
		border-radius: 3px;
	}

	&__new-indicator {
		display: none;
		color: @color-warning-bg;
		font-size: @font-size-default;
		margin-right: @indent-small;
	}

	&__head {
		display: flex;
		color: @color-dark-face-txt-muted;
		font-size: @font-size-kbd;
	}

	&__body {
		display: flex;
		align-items: flex-start;
	}

	&__title {
		flex: 1;
		padding: @indent-small 0;
	}

	&__button {
		display: none;
		padding: @indent-small;
		transition: border-color 0.2s;
		border: 1px solid transparent;
		border-radius: 3px;

		&[href^="http"],
		&[href^="/"] {
			display: inline;
		}

		svg {
			color: @color-link;
		}

		&:hover {
			border-color: @color-face2-bg-lighter;

			svg {
				color: @color-link-hover;
			}
		}
	}

	&__date {
		flex: 1;
	}

	&__author {
		white-space: nowrap;
	}
}
